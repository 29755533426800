.row{
    margin-top: 20px;
    margin-left: 15px;
    color: #fff;
}
.posters{
    display: flex;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.posters::-webkit-scrollbar{
    display: none;
}
.poster{
    cursor: pointer;
    max-height: 250px;
    margin-right: 10px;
}
.smallPoster{
    cursor: pointer;
    max-height: 200px;
    margin-right: 10px;
}
.poster:hover{
    transform: scale(1.1);
}
.smallPoster:hover{
    transform: scale(1.1);
}