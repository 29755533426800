.navbar{
    position: fixed;
    top: 0;
    width: 100%;
    height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
}



.logo{
    position: fixed;
    left: 20px;
    width: 100px;
}
.avatar{
    position: fixed;
    right: 20px;
    width: 30px;
}