
*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
html{
    scroll-behavior: smooth;
}
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #100c08;
}

