.banner{
    background-size: cover;
    max-height: 500px;
    color: #fff;
}
.content{
    padding-top: 140px;
    height: 200px;
    padding-left: 25px;
}
.title{
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 2px;
    padding-bottom: 0.3rem;
}
.button{
    color: white;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 5px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    background-color: rgba(51, 51, 51, .5);
    cursor: pointer;
    margin-right: 1rem;
}
.button:hover{
    color: black;
    background-color: #e6e6e6;
}
.discription{
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: .9rem;
    font-weight: 500;
    height: 80px;
    max-width: 360px;
}
.fade-bottom{
    height: 11.5rem;
    background-image: linear-gradient(180deg,transparent,rgba(37,37,37,.61),#100c08);
}
